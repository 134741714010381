import { Component, Vue } from 'vue-property-decorator'

// services
import { api } from '@/services/api'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'

//wrappers
import { WForm, Padding, Loader, WTable, Sidebar } from '@/components/wrappers';


@Component({
	components:{
    PrivateContent,
		WForm,
		Padding,
		Loader,
		WTable,
		Sidebar,
    HistoryProcess
	}
})
export default class Details extends Vue {
  

  // AIzaSyDm50mrZ6W4sJArDAYGVIrSNx5l-ljzx4o

  mapCenter = { lat: 0, lng: 0 }
  details = {}
  optionsMap: any = {
    // disableDefaultUI: true,
    // scrollwheel: false,
    styles: [
      
    ]
  }
  
  get selectProcess(){
    return this.$store.getters['process/process']
  }

  changeLandmarks(level: any){
    console.log(level);
    this.optionsMap.styles = this.showLandmarks[level];
  }

  
  async mounted(){
    document.title = 'Detalhes';
    const idGroup = this.$route.params.idGroup
    const idProcess = this.$route.params.idProcess
    this.getDetailsById(idGroup, idProcess)
	}

  async getDetailsById(idGroup: string, idProcess: string){
    const url = `/group/${idGroup}/process/${idProcess}/details`;
    await api.get(url)
      .then( ({data}) => {
        console.log(data);
        this.mapCenter.lat = parseFloat(data.lat)
        this.mapCenter.lng = parseFloat(data.long)
        this.details = data
      })
      .catch( (error) => {
        console.log(error);
        
      })
  }

  clickPrint(){
    window.print()
  }

  center = { lat: -18.305675, lng: -39.954242 }

  markers = [
    { lat: -18.307217, lng: -39.953870 },
    { lat: -18.306103, lng: -39.956003 },
    { lat: -18.306034, lng: -39.954085 },
    { lat: -18.307022, lng: -39.953817 }
  ]

  infoOptions = {
    content: 'asdasdasd',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35
      }
  }

  infoWindowPos = null
  infoWinOpen = true
  currentMidx = null

  clickMark(marker: any, idx: any){
    console.log(marker, idx);
    this.infoWindowPos = marker.position;
    this.infoOptions.content = marker.infoText;

    //check if its the same marker that was selected if yes toggle
    if (this.currentMidx == idx) {
      this.infoWinOpen = !this.infoWinOpen;
    }
    //if different marker set infowindow to open and reset current marker index
    else {
      this.infoWinOpen = true;
      this.currentMidx = idx;

    }
  }


  showLandmarkersLevel = 3;
  showLandmarks: any = {
    0: [
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ],
    1: [
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ],
    2: [
      {
        "featureType": "poi.business",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ],
    3: []
  }

}
